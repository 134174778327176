<template>
  <div>
    <v-tabs>
      <v-tab> Members </v-tab>
      <v-tab> Invites </v-tab>
      <v-tab-item>
        <app-members
          :info="info"
          :loader="loader"
          :app_id="app_id"
          :infoError="infoError"
        ></app-members>
      </v-tab-item>
      <v-tab-item>
        <v-row id="appMemberTable">
          <v-col cols="12">
            <v-card outlined>
              <v-card-title>
                <v-icon v-text="'mdi-email-send-outline'" class="mr-3"></v-icon>
                <div class="dark-grey-text">
                  <div class="font-size-h3 bold-text">Pending Invites</div>
                  <div class="text-muted text-body-1">
                    All pending team invites that included this app
                  </div>
                </div>
                <v-spacer></v-spacer>
                <InviteMembersPanel
                  v-if="canManageApp"
                  @makeActive="activeTab = $event"
                  class="side-ptn"
                />
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <no-data
                  v-if="!canManageApp"
                  first-text="No app invites"
                  second-text="You don't have the permission to view or manage the app invites"
                  permission-issues
                ></no-data>
                <invites-list
                  v-else
                  :active-tab-type="activeTab"
                  from-app-page
                  from-dashboard
                  class="mx-3"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import AppMembers from "@/view/components/App/Members.vue";
import InvitesList from "@/view/components/Invites/List";
import InviteMembersPanel from "@/view/components/Invites/InviteMembersPanel.vue";
export default {
  props: ["app_id", "loader", "info", "infoError"],
  components: {
    AppMembers,
    InvitesList,
    InviteMembersPanel,
  },
  data() {
    return {
      activeTab: null,
    };
  },
};
</script>

<style scoped></style>
