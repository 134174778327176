<template>
  <v-card height="100%" outlined>
    <v-card-title>
      <div>
        <div class="text-muted bold-text text-body-1">
          Team members who have access to this app <br /><br />
          Note: Team owners can still join this app at any point of time
          <br />Visit
          <a @click="$router.push({ name: 'team-members' })">team members</a>
          page to manage the role and permissions
        </div>
      </div>
      <v-spacer></v-spacer>
      <AddMemberPanel
        @refresh="loadData"
        :app-detail="info"
        drop-down
        v-if="canManageApp"
      />
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pa-0">
      <div v-if="infoError">
        <no-data :first-text="infoError" permission-issues></no-data>
      </div>
      <div v-else>
        <div class="member-list">
          <!-- loader when data are loading -->
          <v-skeleton-loader
            class="transparent"
            v-if="loader || loading"
            type="table-thead, table-tbody"
          />
          <!-- in case no releases -->
          <no-data
            v-else-if="noInfo"
            :first-text="
              errorMessage
                ? errorMessage
                : 'You do not have permission to view members.'
            "
            permission-issues
          />

          <noData v-else-if="noData" />
          <v-row v-else>
            <v-col cols="12" md="12">
              <v-card flat class="transparent border-0 member-body">
                <!-- loader spinner before the data comes -->
                <Loader v-if="loading" />

                <!-- shows only if data fetch succefuly -->
                <div v-if="!loading">
                  <v-data-table
                    :headers="headers"
                    :items="[...members]"
                    :page.sync="page"
                    :items-per-page="10"
                    :server-items-length="getTotalAppMemberCount"
                    class="
                      v-card v-sheet v-sheet--outlined
                      hide-v-data-footer__select
                      radius-10
                      release-table
                    "
                  >
                    <template #item.name="{ item }">
                      <ProfileImage
                        size="40"
                        :image="item.image"
                        :alt="item.name"
                      />
                      <span class="ml-4">
                        {{ get(item, "name", "") }}
                      </span>
                    </template>
                    <!-- <template #item.current_release="{ item }">
                      <v-row
                        v-if="
                          !isEmpty(item.current_release.android) ||
                          !isEmpty(item.current_release.ios)
                        "
                      >
                        <v-col v-if="!isEmpty(item.current_release.android)">
                          <div>
                            <v-icon
                              v-text="'mdi-android'"
                              small
                              color="primary"
                            ></v-icon>
                          </div>
                          <div class="text-caption">
                            {{
                              `${item.current_release.android.version} ( ${item.current_release.android.version_code} )`
                            }}
                          </div>
                        </v-col>
                        <v-col v-if="!isEmpty(item.current_release.ios)">
                          <div>
                            <v-icon
                              v-text="'mdi-apple'"
                              small
                              color="primary"
                            ></v-icon>
                          </div>
                          <div class="text-caption">
                            {{
                              `${item.current_release.ios.version} ( ${item.current_release.ios.version_code} )`
                            }}
                          </div>
                        </v-col>
                      </v-row>
                      <div v-else>-</div>
                    </template> -->
                    <template #item.status="{ item }">
                      <span
                        :class="
                          item.status == 1 ? 'success--text' : 'error--text'
                        "
                      >
                        {{ item.status == 1 ? "Active" : "Inactive" }}
                      </span>
                    </template>
                    <template #item.join="{ item }">
                      <vue-hoverable-date :date="item.created_at" />
                    </template>

                    <template v-if="canManageTeam" #item.action="{ item }">
                      <action-dropdown
                        id="membersActionDropdown"
                        v-if="user.id != item.user_id && canManageApp"
                      >
                        <template #list>
                          <v-list-item
                            id="removeMemberFromApp"
                            v-if="
                              currentUser.id !== item.user_id && canManageApp
                            "
                            @click="removeMember(item)"
                          >
                            <v-icon small>cancel</v-icon>
                            Remove
                          </v-list-item>
                        </template>
                      </action-dropdown>
                      <v-icon v-else v-text="'mdi-minus'" class="mx-1"></v-icon>
                    </template>
                    <template v-else #item.action="{ item }">
                      <v-icon v-text="'mdi-minus'"></v-icon>
                    </template>
                  </v-data-table>

                  <Modal v-model="showRemoveModal" no-py>
                    <template #message>
                      <v-card-title class="font-weight-bold">
                        Remove member
                      </v-card-title>
                      <v-divider />

                      <v-card-text class="message">
                        <p>
                          Are you sure you want to remove
                          <b>{{ userName }}</b> from this app?
                        </p>

                        <v-alert
                          class="mt-3"
                          border="left"
                          color="info"
                          icon="info"
                          outlined
                        >
                          <b>{{ userName }}</b> will lose the access to any
                          information regarding this app.

                          <div v-if="readMore">
                            <br />
                            Such as:<br />
                            <br />

                            <ul>
                              <li>Releases</li>
                              <li>Notifications</li>
                              <li>Timeline</li>
                            </ul>

                            <br />
                            However, their data such as releases will remain the
                            same in the app and they can still be added again to
                            the app at any point of time.
                          </div>
                          <b
                            class="pointer"
                            @click="readMore = !readMore"
                            v-if="!readMore"
                          >
                            Read more...
                          </b>
                        </v-alert>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions>
                        <v-spacer />
                        <v-btn class="main" @click="onCancelRemoveMember">
                          no
                        </v-btn>
                        <v-btn
                          class="main primary"
                          @click="deleteMember"
                          :loading="busy"
                          >yes</v-btn
                        >
                      </v-card-actions>
                    </template>
                  </Modal>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<style lang="scss">
.Invited {
  color: #fa6400;
}

.Suspended {
  color: #e02020;
}
</style>

<script>
import Vue from "vue";
import { isEmpty, get } from "@/core/services/helper.service";
import { mapGetters, mapMutations, mapState } from "vuex";
import AddMemberPanel from "@/view/components/Team/Members/AddMemberPanel.vue";
import { MEMBERS, DELETE_MEMBER } from "@/store/apps/appmember.module.js";
import { getInviteTime } from "@/core/services/helper.service";
import ActionDropdown from "@/view/components/Common/ActionDropdown";

const MEMBER_STATUS = {
  0: "Suspended",
  1: "Active"
};

const INVITE_STATUS = {
  0: "Rejected",
  1: "Invited"
};

export default {
  name: "app-members",
  props: ["app_id", "loader", "info", "infoError"],
  components: {
    ActionDropdown,
    AddMemberPanel
  },
  data() {
    return {
      get,
      isEmpty,
      readMore: false,
      isLoaded: false,
      MEMBER_STATUS,
      INVITE_STATUS,
      page: 1,
      errorMessage: null,
      selectedId: "",
      loading: true,
      selectedMember: null,
      showRemoveModal: false,
      busy: false,
      error: "d",
      userName: {},
      getInviteTime,
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "status"
        },
        {
          text: "Added to this app",
          align: "start",
          sortable: false,
          width: "25%",
          value: "join"
        },
        {
          text: "Action",
          align: "end",
          sortable: false,
          width: "10%",
          value: "action"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      members: "getMemberList",
      getTotalAppMemberCount: "getTotalAppMemberCount",
      getTotalAppMemberList: "getTotalAppMemberList"
    }),
    ...mapState({
      user: state => state.auth.user
    }),
    noData() {
      return isEmpty(this.members) ? true : false;
    },
    noInfo() {
      return isEmpty(this.info) ? true : false;
    }
  },
  watch: {
    page(val, oldVal) {
      let offset = (val - 1) * 10;
      let limit = val * 10;
      // checked if it is next or previous
      if (val > oldVal) {
        //  if next and all the data are already fetched
        if (this.getTotalAppMemberList.length > oldVal * 10) {
          this.setPreviousAppMemberData(
            this.getTotalAppMemberList.slice(offset, limit)
          );
        } else {
          // if it is first time, get last id and hit api
          const lastId = this.members[this.members.length - 1].id;
          this.loadData(lastId);
        }
      } else {
        // if it is previous then show already fetched data
        this.setPreviousAppMemberData(
          this.getTotalAppMemberList.slice(offset, limit)
        );
      }
    },
    "$route.params.app_id"() {
      this.$store.dispatch(MEMBERS, this.app_id).catch(err => {
        this.notifyErrorMessage({ message: err.message });
      });
    }
  },
  created() {
    this.loading = false;
    this.loadData();
  },
  methods: {
    ...mapMutations({
      removeAppMember: "removeAppMember",
      removeMemberStatsCount: "removeMemberStatsCount",
      setPreviousAppMemberData: "setPreviousAppMemberData",
      removeAppMemberStats: "removeAppMemberStats"
    }),
    removeMember(item) {
      this.selectedId = item.id;
      this.userName = item.name;
      this.showRemoveModal = true;
      Vue.prototype.$analytics.logEvent("app_member_start");
    },
    async deleteMember() {
      this.busy = true;
      try {
        const response = await this.$store.dispatch(DELETE_MEMBER, {
          app_id: this.app_id,
          member_id: this.selectedId
        });
        this.removeAppMember(this.selectedId);
        this.removeAppMemberStats();
        this.setPreviousAppMemberData(
          this.getTotalAppMemberList.slice((this.page - 1) * 10, this.page * 10)
        );
        // this.page = 1;
        this.notifyUserMessage(
          response.message ||
            `${
              this.selectedMemeber ? this.selectedMember.name : "Member"
            } deleted successfully`
        );
        this.showRemoveModal = false;
        Vue.prototype.$analytics.logEvent("app_member");
      } catch (err) {
        this.showRemoveModal = false;
        this.notifyErrorMessage(
          err.message || "Something went wrong, please try again"
        );
        Vue.prototype.$analytics.logEvent("app_member_error");
      }
      this.busy = false;
    },

    onCancelRemoveMember() {
      this.showRemoveModal = false;
      this.selectedMember = null;
      Vue.prototype.$analytics.logEvent("app_member_cancel");
    },
    loadData(lastId) {
      // start the loading spinner
      this.errorMessage = null;
      if (!this.infoError) {
        if (this.loading) return;
        this.loading = true;
        this.$store
          .dispatch(MEMBERS, { app_id: this.app_id, lastId })
          .then(() => {
            this.loading = false;
            if (!lastId) {
              this.page = 1;
            }
          })
          .catch(err => {
            this.errorMessage = err.message || null;
            this.loading = false;
          });
      }
    }
  }
};
</script>
