<template>
  <div class="topbar-item quick-add-member">
    <v-btn
      v-if="!dropDown"
      id="add-member-panel-button"
      class="main"
      color="primary"
      @click="drawer = true"
    >
      <v-icon left>person_add_alt_1</v-icon>
      Add Member
    </v-btn>
    <action-dropdown
      v-else
      button="Add Member"
      id="addMemberFromApp"
      icon="person_add_alt_1"
      :largeBtn="false"
    >
      <template #list>
        <!-- item in the drop down -->
        <v-list-item @click="drawer = true">
          <v-icon small>person_add_alt_1</v-icon>
          Add from team
        </v-list-item>
        <!-- item in the drop down -->
        <v-list-item
          id="inviteToTeamBtn"
          @click="
            $router
              .push({
                name: 'invites',
                query: { appId: appDetail.id, showOption: true }
              })
              .catch(() => {})
          "
        >
          <v-icon small>mdi-email-outline</v-icon>
          Invite to team
        </v-list-item>
      </template>
    </action-dropdown>
    <SidePanel v-model="drawer" width="520" :loading="busy">
      <h1 class="text-center mx-auto mb-8 display-1">Add member</h1>
      <div v-if="!appDetail" class="mt-4">
        <AppAutoComplete class="mb-4" v-model="app" ref="app-autocomplete" />
      </div>
      <div v-else class="text-center bold-text title">
        {{ appDetail.name }}
      </div>
      <member-select
        v-if="!(app && app.meta && app.meta.total_inactive < 1)"
        v-model="selectedMember"
        @isInvite="isInvite = $event"
        invite-important
        add-member
        :app="app"
        :key="app.id"
        @selected="selectedOption = $event"
      />
      <!-- use v-alert for infocard of alerts, it cool and simple -->
      <v-alert
        v-if="app && app.meta && app.meta.total_inactive < 1"
        v-model="alert"
        close-text="Close It"
        color="primary"
        dark
        dismissible
      >
        All team members are already added to this app
      </v-alert>
      <!-- when button  should be disable loading procces -->
      <v-btn
        @click="onAddMember"
        :loading="busy"
        :disabled="
          (app && app.meta && app.meta.total_inactive < 1) || busy || !app.id
        "
        class="main large wide mx-auto mt-13 primary"
        id="invite-member-to-app"
      >
        Submit
      </v-btn>
      <!--  -->
    </SidePanel>
  </div>
</template>

<script>
import Vue from "vue";
import MemberSelect from "@/view/components/Common/MemberSelectSection";
import { validationMixin } from "vuelidate";
import AppAutoComplete from "@/view/components/Common/AppAutoComplete.vue";
import {
  email,
  required,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import { helpers } from "vuelidate/lib/validators";
import { ADD_INVITE } from "@/store/apps/app.module";

const name = helpers.regex("name", /^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/);
const validemail = helpers.regex(
  "validemail",
  /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/
);
import { mapState, mapMutations } from "vuex";

export default {
  name: "add-member-panel",
  mixins: [validationMixin],
  props: {
    dropDown: {
      type: Boolean,
      default: false
    },
    appDetail: {
      type: [Object, Boolean],
      default() {
        return false;
      }
    }
  },
  components: { MemberSelect, AppAutoComplete },
  data() {
    return {
      app: "",
      busy: false,
      alert: true,
      drawer: false,
      selectedMember: "",
      description: "",
      isInvite: true,
      selectedOption: "Current",
      show: false,
      show1: false,
      invalidApp: false,
      openMenu: false,
      members: [],
      search: ""
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
      roles: state => {
        const roles = [];
        Object.keys(state.constants.roles).map(key => {
          roles.push({
            name: key,
            permissions: state.constants.roles[key]
          });
        });
        return roles;
      }
    })
  },
  watch: {
    // this is for navigation drawer to remove the scroll when it open
    drawer: function() {
      // v
      //   ? (document.querySelector("body, html").style.overflow = "hidden")
      //   : (document.querySelector("body, html").style.overflow = "auto");
      this.clearInitialApp([]);
      this.app = "";
      if (this.appDetail) {
        this.app = { ...this.appDetail };
      }
      this.selectedOption = "Current";
    },
    app() {
      this.clearInitialApp([]);
    }
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(30),
        name
      },
      email: {
        required,
        email,
        validemail
      }
    }
  },
  mounted() {},
  created() {
    if (this.appDetail) {
      this.app = { ...this.appDetail };
    }

    Vue.prototype.$analytics.logEvent("app", {
      status: "open",
      action: "add_member"
    });
  },
  methods: {
    ...mapMutations({
      clearInitialApp: "setMemberInitialList",
      clearInitialAppList: "setAppsInitialList",
      appendMemberStatsCount: "appendMemberStatsCount"
    }),
    resetForm() {
      this.form = {
        name: "",
        email: ""
      };
      this.invalidApp = false;
      this.$v.$reset();
    },
    onAddMember() {
      if (!this.app && !this.app.id) {
        this.notifyErrorMessage("Please select app");
        return;
      }
      if (this.busy) return;
      this.busy = true;
      const dataToPost =
        this.selectedOption === "Specific"
          ? {
              app_id: this.app.id,
              platforms: this.app ? this.app.platforms : [],
              details: {
                add_all_team_members: false,
                ids: this.selectedMember
                  ? this.selectedMember.map(x => x.id)
                  : null
              }
            }
          : {
              app_id: this.app.id,
              platforms: this.app ? this.app.platforms : [],
              details: {
                add_all_team_members: true
              }
            };
      this.$store
        .dispatch(ADD_INVITE, dataToPost)
        .then(response => {
          this.busy = false;
          const count = response.message.split("")[0];
          this.appendMemberStatsCount(parseInt(count));
          this.notifyUserMessage(response.message || "Invited Successfully");
          if (this.$route.name !== "app-dashboard") {
            this.$router
              .push({
                name: "app-dashboard",
                params: {
                  app_id: this.app.id
                }
              })
              .catch(() => {});
          }
          this.app = "";
          this.selectedMemeber = [];
          this.clearInitialApp([]);
          this.selectedOption = "Current";
          this.$emit("refresh");
          this.drawer = false;
          Vue.prototype.$analytics.logEvent("app", {
            status: "success",
            action: "add_member"
          });
        })
        .catch(err => {
          this.notifyErrorMessage(
            err.message || `Something went wrong, please try again`
          );
          this.busy = false;
          Vue.prototype.$analytics.logEvent("app", {
            status: "error",
            action: "add_member",
            message: err.message
          });
        });
    },
    onAppSelected(app) {
      this.app = app;
    },
    onRoleSelected({ permissions }) {
      this.permissions = permissions;
    },
    hidePanel() {
      // new KTOffcanvas(KTLayoutQuickAddMember.getElement()).hide();
    },
    updateTags() {
      this.$nextTick(() => {
        this.members.push(...this.search.split(","));
        this.$nextTick(() => {
          this.search = "";
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#quick_add_member {
  overflow: hidden;
}

.quick-add-member {
  .offcanvas {
    right: 0;

    &.offcanvas-right {
      right: -900px;

      &.offcanvas-on {
        right: 0;
      }
    }
  }
}

.invalid-app {
  color: #f64e60;
  font-size: 0.9rem;
}
</style>
