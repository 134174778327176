var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%","outlined":""}},[_c('v-card-title',[_c('div',[_c('div',{staticClass:"text-muted bold-text text-body-1"},[_vm._v(" Team members who have access to this app "),_c('br'),_c('br'),_vm._v(" Note: Team owners can still join this app at any point of time "),_c('br'),_vm._v("Visit "),_c('a',{on:{"click":function($event){return _vm.$router.push({ name: 'team-members' })}}},[_vm._v("team members")]),_vm._v(" page to manage the role and permissions ")])]),_c('v-spacer'),(_vm.canManageApp)?_c('AddMemberPanel',{attrs:{"app-detail":_vm.info,"drop-down":""},on:{"refresh":_vm.loadData}}):_vm._e()],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[(_vm.infoError)?_c('div',[_c('no-data',{attrs:{"first-text":_vm.infoError,"permission-issues":""}})],1):_c('div',[_c('div',{staticClass:"member-list"},[(_vm.loader || _vm.loading)?_c('v-skeleton-loader',{staticClass:"transparent",attrs:{"type":"table-thead, table-tbody"}}):(_vm.noInfo)?_c('no-data',{attrs:{"first-text":_vm.errorMessage
              ? _vm.errorMessage
              : 'You do not have permission to view members.',"permission-issues":""}}):(_vm.noData)?_c('noData'):_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticClass:"transparent border-0 member-body",attrs:{"flat":""}},[(_vm.loading)?_c('Loader'):_vm._e(),(!_vm.loading)?_c('div',[_c('v-data-table',{staticClass:"\n                    v-card v-sheet v-sheet--outlined\n                    hide-v-data-footer__select\n                    radius-10\n                    release-table\n                  ",attrs:{"headers":_vm.headers,"items":[].concat( _vm.members ),"page":_vm.page,"items-per-page":10,"server-items-length":_vm.getTotalAppMemberCount},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_c('ProfileImage',{attrs:{"size":"40","image":item.image,"alt":item.name}}),_c('span',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(_vm.get(item, "name", ""))+" ")])]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_c('span',{class:item.status == 1 ? 'success--text' : 'error--text'},[_vm._v(" "+_vm._s(item.status == 1 ? "Active" : "Inactive")+" ")])]}},{key:"item.join",fn:function(ref){
              var item = ref.item;
return [_c('vue-hoverable-date',{attrs:{"date":item.created_at}})]}},(_vm.canManageTeam)?{key:"item.action",fn:function(ref){
              var item = ref.item;
return [(_vm.user.id != item.user_id && _vm.canManageApp)?_c('action-dropdown',{attrs:{"id":"membersActionDropdown"},scopedSlots:_vm._u([{key:"list",fn:function(){return [(
                            _vm.currentUser.id !== item.user_id && _vm.canManageApp
                          )?_c('v-list-item',{attrs:{"id":"removeMemberFromApp"},on:{"click":function($event){return _vm.removeMember(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("cancel")]),_vm._v(" Remove ")],1):_vm._e()]},proxy:true}],null,true)}):_c('v-icon',{staticClass:"mx-1",domProps:{"textContent":_vm._s('mdi-minus')}})]}}:{key:"item.action",fn:function(ref){
                          var item = ref.item;
return [_c('v-icon',{domProps:{"textContent":_vm._s('mdi-minus')}})]}}],null,true)}),_c('Modal',{attrs:{"no-py":""},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('v-card-title',{staticClass:"font-weight-bold"},[_vm._v(" Remove member ")]),_c('v-divider'),_c('v-card-text',{staticClass:"message"},[_c('p',[_vm._v(" Are you sure you want to remove "),_c('b',[_vm._v(_vm._s(_vm.userName))]),_vm._v(" from this app? ")]),_c('v-alert',{staticClass:"mt-3",attrs:{"border":"left","color":"info","icon":"info","outlined":""}},[_c('b',[_vm._v(_vm._s(_vm.userName))]),_vm._v(" will lose the access to any information regarding this app. "),(_vm.readMore)?_c('div',[_c('br'),_vm._v(" Such as:"),_c('br'),_c('br'),_c('ul',[_c('li',[_vm._v("Releases")]),_c('li',[_vm._v("Notifications")]),_c('li',[_vm._v("Timeline")])]),_c('br'),_vm._v(" However, their data such as releases will remain the same in the app and they can still be added again to the app at any point of time. ")]):_vm._e(),(!_vm.readMore)?_c('b',{staticClass:"pointer",on:{"click":function($event){_vm.readMore = !_vm.readMore}}},[_vm._v(" Read more... ")]):_vm._e()])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"main",on:{"click":_vm.onCancelRemoveMember}},[_vm._v(" no ")]),_c('v-btn',{staticClass:"main primary",attrs:{"loading":_vm.busy},on:{"click":_vm.deleteMember}},[_vm._v("yes")])],1)]},proxy:true}],null,false,1543588232),model:{value:(_vm.showRemoveModal),callback:function ($$v) {_vm.showRemoveModal=$$v},expression:"showRemoveModal"}})],1):_vm._e()],1)],1)],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }